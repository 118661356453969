<template>
  <div id="app">
    <!-- 路由占位符 -->
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "app",
  methods: {
      getQueryVariable() {
      // 获得了当前链接的中?号后的参数,location.search的值就是 '?xxx=xxx ' ,substr(1)就是从索引1开始截取
      var url = window.location.href;
      // console.log(url);
      // console.log(url.split("#")[1]);
      var urlItem = url.split("#")[1];
      localStorage.setItem("href", url);
      localStorage.setItem("hrefItem", urlItem);
    },
  },
  created() {
    this.getQueryVariable();
  },
};
</script>

<style>
.BoxHeight {
  min-height: calc(100vh - 70px);
  padding-top: 50px;
  box-sizing: border-box;
}
.BoxHeight2 {
  height: 100vh;
  padding-top: 50px;
  box-sizing: border-box;
}
/* // 下拉菜单 */
.el-dropdown-menu__item {
  margin: 5px;
}
.dropdown_item span {
  color: #818181;
}

/* 获取验证码位置调整 */
.el-input__suffix-inner {
  padding-right: 10px;
}

/* 测试按钮 */
.testBtn {
  background-color: #1f8bd0 !important;
  border-color: #1f8bd0 !important;
  width: 198px !important;
  border-radius: 0 !important;
  font-size: 16px !important;
  transition: all 0.3s !important;
}
.noMessage {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.radius50 {
  border-radius: 50%;
}
</style>
