import Cookies from 'js-cookie'
import { getLanguage } from '@/lang/index'

const state = {
  device: 'desktop',
  preViewFile:'',
  language: getLanguage(),
  seeFlag:false
}

const mutations = {
  SET_LANGUAGE: (state, language) => {
    state.language = language
    Cookies.set('language', language)
  },
  SET_PreViewFile:(state,url)=>{
    state.preViewFile = url
  },
  SET_SeeFlag:(state,falg)=>{
    state.seeFlag = falg
  },
  
}

const actions = {
  setLanguage({ commit }, language) {
    commit('SET_LANGUAGE', language)
  },
  setPreViewFile:({ commit },url)=>{
    commit('SET_PreViewFile', url)
  },
  setSeeFlag:({ commit },falg)=>{
    commit('SET_SeeFlag', falg)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
