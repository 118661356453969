import Vue from "vue";
import VueRouter from "vue-router";
// 路由懒加载
const Login = () => import("../views/Login.vue");
const Register = () => import("../views/Register.vue");
const ForgetPwd = () => import("../views/ForgetPwd.vue");
const Home = () => import("../views/Home.vue");
const NoticeDetail = () => import("../views/NoticeDetail.vue");
const Search = () => import("../views/Search.vue");
const InstrumentData = () => import("../views/InstrumentData.vue");
const InformationDetail = () => import("../views/InformationDetail.vue");
const My = () => import("../views/my/My.vue");
const PersonalInfo = () => import("../views/my/PersonalInfo.vue");
const MyMessage = () => import("../views/my/MyMessage.vue");
const TrainingTest = () => import("../views/my/TrainingTest.vue");
const TestRecord = () => import("../views/my/TestRecord.vue");
const TrainingDetail = () => import("../views/my/TrainingDetail.vue");
const TestCenter = () => import("../views/my/TestCenter.vue");
const Favorites = () => import("../views/my/Favorites.vue");
const BrowserHistory = () => import("../views/my/BrowserHistory.vue");
const ExamType = () => import("../views/exam/ExamType.vue");
const TestDetail = () => import("../views/TestDetail.vue");
const Examination = () => import("../views/exam/Examination.vue");
const ExamCompleted = () => import("../views/exam/ExamCompleted.vue");
const ExamResult = () => import("../views/exam/ExamResult.vue");
const Science = () => import("../views/IconMenu/Science.vue");
const Tools = () => import("../views/IconMenu/Tools.vue");
const Upload = () => import("../views/IconMenu/Upload.vue");
const PicPreview = () => import("../views/PicPreview.vue");
const PreviewFile = () => import("../views/PreviewFile.vue");
const AccountInfo = () => import("../views/my/AccountInfo.vue");
const HistoryExam = () => import("../views/my/HistoryExam.vue");
const Advertising = () => import("../views/Advertising.vue");
const UseClauses = () => import("../views/UseClauses.vue");
const RegInstructions = () => import("../views/RegInstructions.vue");

Vue.use(VueRouter);

const routes = [
  { path: "/", redirect: "/login" },
  { path: "/login", component: Login, meta: { title: '登录'}  },
  { path: "/register", component: Register,meta: { title: '注册'} },
  { path: "/forgetPwd", component: ForgetPwd,meta: { title: '忘记密码'} },
  { path: "/index", component: Home ,meta: { title: ' 爱科来售后服务网站'}},
  { path: "/noticeDetail", component: NoticeDetail ,meta: { title: '公告详情'}},
  { path: "/search", component: Search ,meta: { title: '搜索'}},
  { path: "/instrumentData", component: InstrumentData ,meta: { title: '仪器资料'}},
  { path: "/informationDetail", component: InformationDetail ,meta: { title: '仪器资料详情'}},
  { path: "/my", component: My ,meta: { title: '个人信息'}},
  { path: "/personalInfo", component: PersonalInfo ,meta: { title: '个人资料'}},
  { path: "/myMessage", component: MyMessage ,meta: { title: '我的消息'}},
  { path: "/trainingTest", component: TrainingTest ,meta: { title: '培训测试'}},
  { path: "/testRecord", component: TestRecord ,meta: { title: '测试记录'}},
  { path: "/trainingDetail", component: TrainingDetail ,meta: { title: '培训详情'}},
  { path: "/testCenter", component: TestCenter ,meta: { title: '考试中心'}},
  { path: "/favorites", component: Favorites ,meta: { title: '收藏夹'}},
  { path: "/browserHistory", component: BrowserHistory ,meta: { title: '浏览记录'}},
  { path: "/examType", component: ExamType ,meta: { title: '考试类型'}},
  { path: "/testDetail", component: TestDetail ,meta: { title: '详情'}},
  { path: "/examination", component: Examination ,meta: { title: '考试中'}},
  { path: "/examCompleted", component: ExamCompleted ,meta: { title: '提交成功'}},
  { path: "/examResult", component: ExamResult ,meta: { title: '提交成功'}},
  { path: "/science", component: Science ,meta: { title: '学术相关'}},
  { path: "/tools", component: Tools ,meta: { title: '工具使用'}},
  { path: "/upload", component: Upload ,meta: { title: '资料下载'}},
  { path: "/picPreview", component: PicPreview ,meta: { title: '零部件展示'}}, 
  { path: "/previewFile", component: PreviewFile ,meta: { title: '文件预览'}},
  { path: "/accountInfo", component: AccountInfo ,meta: { title: '账号信息'}},
  { path: "/historyExam", component: HistoryExam ,meta: { title: '账号信息'}},
  { path: "/advertising", component: Advertising, meta: { title: '隐私说明'}},
  { path: "/useClauses", component: UseClauses, meta: { title: '使用条款' } },
  { path: "/regInstructions", component: RegInstructions, meta: { title: '用户注册说明'}}
];

const router = new VueRouter({
  routes
});

//挂载路由导航守卫,to表示将要访问的路径，from表示从哪里来，next是下一个要做的操作 next('/login')强制跳转login
router.beforeEach((to, from, next) => {
  // 访问登录页，放行
  if (to.path === '/login' || to.path === '/useClauses' || to.path === '/advertising' || to.path === '/register' || to.path === '/forgetPwd' || to.path === '/regInstructions') return next()
  // 获取token
  var member = JSON.parse(localStorage.getItem('member'))
  if(member != null){
    const isLogin =  JSON.parse(localStorage.getItem('member')).isLogin;
    // 没有token, 强制跳转到登录页isLogin
    if (typeof undefined === isLogin || null === isLogin || "" === isLogin) return next('/login')
    next()
  }else{
    return next('/login')
  }
})
export default router;
