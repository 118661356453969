import Vue from 'vue'
import App from './App.vue'
import router from './router'
import $ from 'jquery'
import './plugins/element.js'
// 导入全局样式
import '../public/static/css/common.css'
import '../public/static/css/reset.css'
import store from './store'

// 导入NProgress, 包对应的JS和CSS
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

// 按需引入vant组件
import { SwipeCell } from 'vant';
import { Dialog } from 'vant';
import { Uploader } from 'vant';
import { Loading } from 'vant';
import { Button,Swipe, SwipeItem,Lazyload, Collapse, CollapseItem  } from 'vant';

Vue.use(Button);
Vue.use(Uploader);
Vue.use(SwipeCell);
Vue.use(Dialog);
Vue.use(Loading);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Lazyload);
Vue.use(Collapse);
Vue.use(CollapseItem);

import i18n from './lang'
import axios from 'axios'
 axios.defaults.baseURL = 'https://www.arkray-service.com.cn/api/'
// axios.defaults.baseURL = 'http://192.168.0.168:8090/api/'
// axios.defaults.baseURL = 'http://127.0.0.1:8091/api/'
// axios.defaults.baseURL = ' http://192.168.0.170:8090/api/'
// axios.defaults.baseURL = 'http://114.55.93.223:8092/api/'
//测试环境地址
// axios.defaults.baseURL = 'http://101.37.17.192:8091/api/'

// 在request 拦截器中, 展示进度条 NProgress.start()
// 请求在到达服务器之前，先会调用use中的这个回调函数来添加请求头信息
axios.interceptors.request.use(config => {
  NProgress.start()
  // console.log(config)
  // 为请求头对象，添加token验证的Authorization字段
  config.headers.Authorization = window.sessionStorage.getItem('token')
  // 在最后必须 return config
  return config
})
// response 拦截器中,  隐藏进度条NProgress.done()
axios.interceptors.response.use(config => {
  NProgress.done()
  return config
})
// 挂在到Vue实例，后面可通过this调用
Vue.prototype.$http = axios

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
})

Vue.config.productionTip = false

Vue.filter('dataFormat', function (originVal) {
  const dt = new Date(originVal)

  const y = dt.getFullYear()
  const m = (dt.getMonth() + 1 + '').padStart(2, '0')
  const d = (dt.getDate() + '').padStart(2, '0')

  const hh = (dt.getHours() + '').padStart(2, '0')
  const mm = (dt.getMinutes() + '').padStart(2, '0')
  const ss = (dt.getSeconds() + '').padStart(2, '0')
  // yyyy-mm-dd hh:mm:ss
  return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
})

//路由跳转回到顶部
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
})
/* 路由发生变化修改页面title */
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')