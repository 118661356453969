export default {
  notice:"こうこく",
  closed:"閉鎖",
  navbar: {
    dashboard: "トップ",
    github: "GitHub",
    logOut: "ログアウト",
    profile: "プロフィール",
    theme: "テーマ変更",
    size: "画面サイズ"
  },
  login: {
    logIn: "ログイン",
    phone: "携帯電話の番号",
    password: "パスワード",
    keepPwd: "ログインにしたままにする",
    reg: "新規ユーザー登録",
    lostPwd: "パスワードを忘れた場合",
    useManual:"ユーザーマニュアル",
    readTip:"あなたはこれを読んで同意してください",
  },
  reg: {
    title: "登録",
    username: "氏名",
    age: "年齢",
    phone: "連絡先",
    comName: "会社名",
    comAddr: "会社の住所",
    position: "ポジション",
    experYears: "経験年数",
    email: "メール",
    authCode: "検証コード",
    getCode: "captchaの取得",
    agree:'あなたはこれを読んで同意してください',
    explain: "ユーザー契約・ユーザーのプライバシー"
  },
  forgetPwd: {
    title: "パスワードを忘れた",
    pwd: "パスワードを設定してください",
    newPwd: "新しいパスワードを設定してください",
    confirm: "確認"
  },
  footer: {
    wapLink:'携帯電話版ページ',
    friendlyLink:'ホットリンク',
    aboutUs:'私たちについて',
    link1:'ARKRAY主页',
    link2:'遠隔メンテナンス',
    new:'ニュースリリース',
    comName:'愛科来国際貿易(上海)有限公司',
    phone:'電話: ',
    addr:'住所：上海市浦東新区金科路2889弄3号長泰広場c座202室',
    privacyPolicy: "プライバシーの説明",
    UseClauses: "利用規約"
  },
  navbar: {
    personalData: "個人情報",
    accountInfo:"アカウント情報",
    myMessage: "私のニュース",
    trainingTest: "訓練テスト",
    testCenter: "試験センター",
    testRecord:"試験記録",
    favorites: "お気に入り",
    browserHistory: "閲覧記録",
    logOut: "ログアウト"
  },
  more: "より",
  iconMenu: {
    academic: "学術関係",
    fault: "故障マニュアル",
    maintain: "新情報発表",
    tool: "ツール使用",
    exam: "教育•検証",
    datum: "社内資料に向けて",
    upload:'資料のダウンロード',
    trainingMaterials:'試験勉強の資料を養成する',
    academicTest:'学術テスト',
    primaryTest:'初級試験',
    juniorTest:'初中級試験',
    intermediateTest:'中級試験',
    advancedTest:'上級試験',
  },
  searchContent: "検索内容を入力してください",
  search:"検索",
  emptySearch:"履歴検索を空にします",
  searchHistory:'歴史検索',
  searching:'検索中です',
  documentClass:'文書類',
  InstrumentClass:'計器類',
  my: {
    baseInfo: "基本情報",
    avatar:"顔",
    accountInfo: "アカウント情報",
    amend: "修正",
    inputName: "お名前を入力してください",
    inputAge: "あなたの年齢を入力してください",
    inputComName: "会社名を入力してください",
    inputComAddr: "会社の住所を入力してください",
    inputPosition: "あなたのポジションを入力してください",
    inputWorkYears: "経験年数を入力してください",
    inputEmail: "メールアドレスを入力してください",
    account: "アイディー ",
    password:"パスワード",
    checkPwd: "元のパスワードを確認してください",
    min: "最小長は6文字です",
    pwd: "古い暗号",
    newPwd: "新しい暗号",
    confirmPwd: "新しいパスワードの確認",
    cancle: "取り消",
    confirm: "確 認",
    changePwd: "パスワードを変更する",
    sign: "既読にします",
    noMessage: "消息がない~",
    seeMore:'もっと見る',
    publishTime:'発表時期',
    deadline:'締切り時間',
    testLogging:'試験記録',
    noTestLogging:'記録なし~',
    viewingHours:'閲覧時間',
    TestRecords:'試験の記録',
    points:'分',
    examDates:'試験日',
    goBack:'戻る',
    download:'ダウンロード',
    startExam:'試験を始める',
    testTime:'テスト時間',
    noFurtherRecords:'それ以上の記録はまだない',
    startTest:'テストを始める',
    through:'を通じて',
    unqualified:'不合格',
    changeInfo:"資料を修正する",
    changePwd:"パスワードを修正する",
  },
  noData:'資料がない~',
  home:'しょおもて',
  viewTestResults:'試験の結果を見る',
  returnHome:'トップページに戻る',
  countdown:'カウントダウン',
  answer:'解答',
  completeSubmission:'提出を完了する',
  testResults:'テストの結果',
  Tools:'ツール',
  advert:{
    Advertising:"プライバシーの説明",
    TopInstructions:'あなたの信頼は私达にとって非常に重要で、私达はユーザーの情報のセキュリティの重要性をよく知って、私达は法律と法規の要求に基づいて、セキュリティ保護措置を采用して、あなたのユーザーの情報のセキュリティを制御することができます。鑑これ、(や「我々」と略称)制定本『法律声明やプライバシー政策』(以下、または「声明と政策」と略称)をご注意し、各種サービスの使用前にはぜひよくよく理解して本を読む『法律声明と私生活政策』は、確認は十分理解して同意を後方関連製品とサービスを使用。使用を開始すると、本声明およびポリシーの内容を受け入れたものとみなされます。',
    AccountRegistrationUse:'1、アカウントの登録と使用',
    TheTermsOfOneFirst:'1.1 利用者は、アイコーが提供するウェブサービスを利用する際にアカウントの登録が必要となる場合があります。登録が完了した場合、メールアドレス、携帯電話番号、ブログアカウント名、設定したパスワードでログインすることができます。',
    TheTermsOfOneSecond:'1.2 登録時に設定したアカウント名とパスワードは、愛科があなたを識別するための唯一の有効なidとなります。このアカウント名とパスワードは、あなた自身が設定し、あなたの責任で保管することを特別に注意します。あなたは、あなたの名前とパスワードでログインした後に行われたすべての活動と事件に対して法的責任を負うものとします。',
    TheTermsOfOneThird:'1.3 アカウントやパスワードを他人に譲渡したり、貸したりしてはいけません。不正に利用されていることが確認された場合は、速やかにお知らせください。ハッカー行為やあなたの不注意な保管によるアカウントやパスワードの不正使用について、当社は一切責任を負いません。',
    TheTermsOfOneFourth:'1.4 愛科来ネットワークサービス提供者として、愛科来の各ネットワークサービスをよりよく利用し、アカウントの安全を保障するために、愛科来はあなたの韓国の法律規定に従って、実名認証を完了することを要求することができます。',
    TheTermsOfOneFifth:'1.5 お客様は、acoleのウェブサービスに登録し、利用する際に、真実、正确、完全、合法的かつ有効な情報をacoleに提供しなければなりません。また、その情報を維持し、更新する義務があります。お客様が提供した情報が本条に違反した場合、それに伴う責任と結果についてはお客様の責任となります。また、当社は、お客様の各種webサービスの利用を終了する権利を有します。',
    TheTermsOfOneSixth:'1.6 18歳未満の方は、保護者または法定後見人の参加を得て登録を済ませ、当社が提供する法律サービスを利用してください。',
    AccountRegistrationUseTwo:'2、アカウントの登録と使用',
  },
  startPreview:'プレビューを始める',
  describe:'描写',
  regInstruct:{
    RegistrationForNewUsers:'新規ユーザー登録の手順',
  },
  useClause:{
    useTerms:'利用規約',
  },
  inputAnswer:'あなたの答えを入力してください',
  lazyFetch:'秒後取得',
  getSMS:'メールを受け取る',
  examMsgStart:'試験を始める',
  examMsgTesting:'テストを始める',
  slideTip:'スライド表示',
  title:"愛科のアフターサービスサイトへようこそ",
  scoreTip:'添削中',
  agreeTip:"以上に同意する"
};
